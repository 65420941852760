import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from 'App';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'style.css';

import favicon from './img/fsu-favicon.png'; // Import favicon

const link = document.createElement('link');
link.rel = 'shortcut icon';
link.type = 'image/x-icon';
link.href = favicon;
document.head.appendChild(link);

if (process.env.REACT_APP_ENVIRONMENT !== 'development' && process.env.REACT_APP_API_URL !== undefined && process.env.REACT_APP_SENTRY_DSN !== undefined) {
  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    integrations: function (integrations) {
      return [
        ...integrations,
        Sentry.browserTracingIntegration({
          tracingOrigins: [`${process.env.REACT_APP_API_URL}`],
        }),
      ];
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    sampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
