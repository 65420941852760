import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { Col, Container, Row, Nav, Navbar } from 'react-bootstrap';
import { OrderList } from 'pages/order-list';
import { Turnover } from 'pages/turnover';
import { LogisticsOverview } from 'pages/logistics-overview';
import { LogisticsTool } from 'pages/logistics-tool';
import { HomePage } from 'pages/homepage';
import { Admin } from 'pages/admin';
import { Export } from 'pages/export';

import companyLogo from '../../img/fsu-logo.png';

export const MainPage: React.FC = () => {
  return (
    <BrowserRouter>
      <Container fluid className="main-wrapper">
        <Row className="header mb-4">
          <Col>
            <Navbar expand="lg">
              <Navbar.Brand>
                <LinkContainer to="/">
                  <Nav.Link>
                    <img src={companyLogo} className="logo" alt="FSU logo" loading="lazy" />
                  </Nav.Link>
                </LinkContainer>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <LinkContainer to="/order-list">
                    <Nav.Link>Ordreliste</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/turnover">
                    <Nav.Link>Omsætning</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/logistics-overview">
                    <Nav.Link>Logistik Oversigt</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/logistics-tool">
                    <Nav.Link>Logistik værktøj</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/admin">
                    <Nav.Link>Admin</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/export">
                    <Nav.Link>Eksport</Nav.Link>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>

        <Row className="main-content">
          <Col sm={12} className="content-wrapper">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/order-list" element={<OrderList />} />
              <Route path="/turnover" element={<Turnover />} />
              <Route path="/logistics-overview" element={<LogisticsOverview />} />
              <Route path="/logistics-tool" element={<LogisticsTool />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/export" element={<Export />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </BrowserRouter>
  );
};
