import fetcher from 'lib/fetcher';
import type { AxiosResponse } from 'axios';

//Gets projects where 'projects_delivery_date' in the database, usageperiod_end in API response.
export const getProjectsByDate = async (startDate: string, endDate: string): Promise<AxiosResponse | null> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/projects-by-date`);
  const params = url.searchParams;

  params.append('startDate', startDate);
  params.append('endDate', endDate);

  return await fetcher(url.toString(), 'GET');
};

//Gets Invoices from 'date' property i Rentman API response, within the two startDate and endDate.
export const getInvoicesByDate = async (startDate: string, endDate: string): Promise<AxiosResponse | null> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/invoices-by-date`);
  const params = url.searchParams;

  params.append('startDate', startDate);
  params.append('endDate', endDate);

  return await fetcher(url.toString(), 'GET');
};

export const getYearlyTurnoverWeek = async (department: string, year: number): Promise<AxiosResponse | null> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/yearly-turnover-week`);
  const params = url.searchParams;

  params.append('department', department);
  params.append('year', year.toString());

  return await fetcher(url.toString(), 'GET');
};

export const getYearlyTurnoverMonth = async (department: string, year: number): Promise<AxiosResponse | null> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/yearly-turnover-month`);
  const params = url.searchParams;

  params.append('department', department);
  params.append('year', year.toString());

  return await fetcher(url.toString(), 'GET');
};

export const getOrderlist = async (department: string, daysAhead: number): Promise<AxiosResponse | null> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/orderlist`);
  const params = url.searchParams;

  params.append('department', department);
  params.append('daysAhead', daysAhead.toString());

  return await fetcher(url.toString(), 'GET');
};

export const getCars = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/cars`, 'GET');
};

export const createCar = async (carName: string): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/cars`, 'POST', {
    carName,
  });
};

export const updateCar = async (carId: number, carName: string): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/cars`, 'PUT', {
    carId,
    carName,
  });
};

export const deleteCar = async (carId: number): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/cars`, 'DELETE', {
    carId,
  });
};

export const getCrews = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/crews`, 'GET');
};

export const createCrew = async (crewName: string): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/crews`, 'POST', {
    crewName,
  });
};

export const updateCrew = async (crewId: number, crewName: string): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/crews`, 'PUT', {
    crewId,
    crewName,
  });
};

export const deleteCrew = async (crewId: number): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/crews`, 'DELETE', {
    crewId,
  });
};

export const getDrivingTimes = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/getDrivingTimes`, 'GET');
};

export const getLogisticsData = async (date: string, department: string): Promise<AxiosResponse | null> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/logisticsData`);
  const params = url.searchParams;

  params.append('department', department);
  params.append('date', date);

  return await fetcher(url.toString(), 'GET');
};

export const getTrips = async (date: string, department: string): Promise<AxiosResponse | null> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/getTripsByDate`);
  const params = url.searchParams;

  params.append('department', department);
  params.append('date', date);

  return await fetcher(url.toString(), 'GET');
};

export const getCounters = async (date: string, department: string): Promise<AxiosResponse | null> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/counters`);
  const params = url.searchParams;

  params.append('department', department);
  params.append('date', date);

  return await fetcher(url.toString(), 'GET');
};

export const createTrip = async (date: string, department: string, order: number): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/trips`, 'POST', {
    department,
    order,
    date,
  });
};

export const deleteTrip = async (tripId: number): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/trips`, 'DELETE', {
    tripId,
  });
};

export const getNewFileUrl = async (fileId: number): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/new-file-url`, 'POST', { fileId });
};

export const checkPdfUrl = async (fileUrl: string): Promise<AxiosResponse | null> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/check-pdf`);
  url.searchParams.append('url', fileUrl);

  return await fetcher(url.toString(), 'GET');
};

export const createLogistic = async (tripId: number, cars: number[], crews: number[], projects: number[]): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/logistic`, 'POST', {
    tripId,
    cars,
    crews,
    projects,
  });
};

export const updateLogistic = async (
  logisticId: number,
  data: {
    logistics_trip_id?: number;
    logistics_cars?: number[];
    logistics_crews?: number[];
    logistics_projects?: number[];
    logistics_order?: number;
  },
): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/logistic`, 'PUT', {
    logisticId,
    data: data,
  });
};

export const deleteLogistic = async (logisticId: number): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/logistic`, 'DELETE', {
    logisticId,
  });
};
