import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Alert, CloseButton, Button, Overlay, Popover } from 'react-bootstrap';
import { ArrowLeftCircle, ArrowRightCircle, PersonFill } from 'react-bootstrap-icons';
import type { TripContentInterface, CrewInterface, ProjectInterface, DrivingTimesInterface } from './types';
import { checkPdfUrl, getNewFileUrl } from 'Api';

export const LogisticsGridTripsContent: React.FC<TripContentInterface> = ({
  logistic,
  allCrews,
  allTimes,
  selectedCrews,
  deliverProjects,
  returnProjects,
  selectedProjects,
  removeLogisticItem,
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const [activeProject, setActiveProject] = useState<ProjectInterface | null>(null);
  const [pdfUrls, setPdfUrls] = useState<{ [fileId: number]: string }>({});
  const containerRef = useRef<HTMLDivElement>(null);

  const findCrew = (crewId: number): CrewInterface | null => {
    return allCrews.find((crew) => crew.crew_id === crewId) || null;
  };

  const findDrivingTimes = (drivingTimeId: number): DrivingTimesInterface | null => {
    return allTimes.find((time) => time.driving_times_id === drivingTimeId) || null;
  };

  const findProject = (projectId: number): ProjectInterface | null => {
    let foundProject: ProjectInterface | null = null;
    const deliverProject = deliverProjects.find((p) => p.projects_id === projectId);
    if (deliverProject) {
      foundProject = { ...deliverProject, deliverType: 'delivery' };
    }
    const returnProject = returnProjects.find((p) => p.projects_id === projectId);
    if (returnProject) {
      foundProject = { ...returnProject, deliverType: 'returning' };
    }
    return foundProject;
  };

  useEffect(() => {
    async function checkAllSelectedProjectFiles() {
      for (const projectId of selectedProjects) {
        const project = findProject(projectId);
        if (!project) continue;

        for (const file of project.files) {
          try {
            const response = await checkPdfUrl(file.project_files_url);

            if (response?.data.status === 'success') {
              setPdfUrls((prev) => ({
                ...prev,
                [file.project_files_id]: file.project_files_url,
              }));
            } else if (response?.data.status === 403) {
              const newFileUrl = await getNewFileUrl(file.project_files_id);
              if (newFileUrl?.data) {
                setPdfUrls((prev) => ({
                  ...prev,
                  [file.project_files_id]: newFileUrl.data,
                }));
              }
            }
          } catch (err) {
            console.error('Error checking PDF URL:', err);
          }
        }
      }
    }

    if (selectedProjects.length > 0) {
      checkAllSelectedProjectFiles();
    }
  }, [selectedProjects, deliverProjects, returnProjects]);

  const handleShowPdf = (event: React.MouseEvent<HTMLButtonElement>, project: ProjectInterface) => {
    setShow(false);
    setTarget(event.currentTarget);
    setActiveProject(project);
    setShow(!show);
  };

  return (
    <div ref={containerRef}>
      <Row className="mb-2">
        <Col sm={12}>
          {selectedCrews.map((crewId, index) => {
            const crew = findCrew(crewId);
            if (!crew) return null;

            return (
              <Alert key={`${logistic.logistics_id}-${crewId}-${index}`} variant="secondary">
                <PersonFill /> {crew.crew_name}
                <CloseButton className="btn-delete" onClick={() => removeLogisticItem('crew', crewId, logistic)} />
              </Alert>
            );
          })}
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          {selectedProjects.map((projectId, index) => {
            const project = findProject(projectId);
            if (!project) return null;

            return (
              <Alert key={`${logistic.logistics_id}-${projectId}-${index}`} variant={project.deliverType === 'delivery' ? 'success' : 'danger'}>
                {project.deliverType === 'delivery' ? <ArrowRightCircle /> : <ArrowLeftCircle />} {project.projects_customer_name}
                <br />
                {project.projects_address}
                <br />
                {project.projects_zipcode} {project.projects_city}
                <br />
                {project.projects_driving_times.map((drivingTimeId) => {
                  const foundDrivingTimes = findDrivingTimes(drivingTimeId);
                  if (foundDrivingTimes) {
                    return <span key={foundDrivingTimes.driving_times_id}>{foundDrivingTimes.driving_times_name}</span>;
                  }
                  return null;
                })}
                <CloseButton className="btn-delete" onClick={() => removeLogisticItem('project', projectId, logistic)} />
                {project.files.length > 0 && (
                  <div className="pdf-popup">
                    <Button size="sm" variant="secondary" onClick={(e) => handleShowPdf(e, project)}>
                      Vis PDF
                    </Button>
                  </div>
                )}
              </Alert>
            );
          })}
        </Col>
      </Row>

      <Overlay show={show} target={target} placement="right" container={containerRef} containerPadding={20}>
        <Popover className="custom-popover">
          <Popover.Body>
            {activeProject?.files.map((file, index) => {
              const fileUrl = pdfUrls[file.project_files_id] || file.project_files_url;

              return (
                <Row key={index}>
                  <Col sm={12}>
                    <p>{file.project_files_name}</p>
                    <embed src={fileUrl + '#zoom=100'} type="application/pdf" width="800px" height="800px" />
                  </Col>
                </Row>
              );
            })}
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};
